export const races = [
  'Artrell',
  'Centaur',
  'Dwarf',
  'Elf',
  'Faerie',
  'Giant',
  'Gnoll',
  'Gnome',
  'Goblin',
  'Half-elf',
  'Half-ogre',
  'Half-orc',
  'Halfling',
  'Hobbit',
  'Human',
  'Imp',
  'Kender',
  'Kobold',
  'Nymph',
  'Ogre',
  'Ogre-magi',
  'Orc',
  'Satyr',
  'Troll',
  'Dragon',
  'Drow-elf',
  'Lich',
  'Were-wolf',
];

export const raceStats = {
  Strength: {
    1: ['Satyr', 'Troll', 'Dragon', 'Giant', 'Were-wolf'],
    2: ['Centaur', 'Dwarf', 'Orc', 'Half-ogre', 'Ogre', 'Goblin', 'Ogre-magi', 'Drow-elf', 'Gnoll', 'Kobold'],
    4: ['Nymph', 'Faerie', 'Imp'],
  },
  Charisma: {
    1: ['Dragon', 'Nymph', 'Human'],
    2: ['Half-elf', 'Hobbit', 'Faerie'],
    4: ['Satyr', 'Centaur', 'Gnome', 'Troll', 'Ogre', 'Drow-elf', 'Artrell', 'Ogre-magi', 'Giant'],
  },
  Constitution: {
    1: ['Dragon', 'Ogre', 'Centaur', 'Kender'],
    2: ['Ogre-magi', 'Orc', 'Half-orc', 'Kobold', 'Gnoll', 'Giant', 'Troll'],
    4: ['Faerie', 'Halfling', 'Nymph', 'Imp'],
  },
  Dexterity: {
    1: ['Artrell', 'Dragon', 'Halfling', 'Hobbit'],
    2: [
      'Half-elf',
      'Nymph',
      'Satyr',
      'Kender',
      'Faerie',
      'Goblin',
      'Kobold',
      'Half-orc',
      'Drow-elf',
      'Were-wolf',
      'Imp',
      'Centaur',
    ],
    4: ['Ogre-magi'],
  },
  Intelligence: {
    1: ['Dragon', 'Lich', 'Imp', 'Elf', 'Gnome'],
    2: ['Imp', 'Faerie', 'Ogre-magi', 'Drow-elf', 'Half-ogre', 'Halfling', 'Kender'],
    4: ['Hobbit', 'Centaur'],
  },
  Wisdom: {
    1: ['Dragon', 'Lich', 'Dwarf', 'Nymph', 'Imp'],
    2: ['Gnome', 'Elf', 'Faerie', 'Ogre-magi', 'Halfling', 'Human', 'Drow-elf', 'Giant', 'Artrell'],
    4: ['Kender', 'Centaur'],
  },
};

export const raceCfg = {
  Artrell: {
    sight: 2,
    weight: 5800,
    fingers: 2,
    wielding: 4,
    str: -5,
    con: -9,
    int: -2,
    wis: -2,
    dex: 6,
    cha: -6,
  },
  Centaur: {
    sight: 0,
    weight: 13000,
    fingers: 5,
    wielding: 2,
    str: 5,
    con: 6,
    int: -4,
    wis: -4,
    dex: 3,
    cha: -5,
  },
  Dragon: {
    sight: 2,
    weight: 19999,
    fingers: 3,
    wielding: 0,
    str: 9,
    con: 9,
    int: 9,
    wis: 9,
    dex: 9,
    cha: 9,
  },
  'Drow-elf': {
    sight: 4,
    weight: 7000,
    fingers: 5,
    wielding: 2,
    str: -2,
    con: 0,
    int: 7,
    wis: 8,
    dex: 2,
    cha: 2,
  },
  Dwarf: {
    sight: 1,
    weight: 6000,
    fingers: 4,
    wielding: 2,
    str: 2,
    con: 4,
    int: -1,
    wis: 4,
    dex: 3,
    cha: -4,
  },
  Elf: {
    sight: 3,
    weight: 10001,
    fingers: 5,
    wielding: 2,
    str: 0,
    con: 0,
    int: 5,
    wis: 8,
    dex: 3,
    cha: 1,
  },
  Faerie: {
    sight: -1,
    weight: 5001,
    fingers: 5,
    wielding: 2,
    str: -9,
    con: -9,
    int: 8,
    wis: 9,
    dex: 9,
    cha: 5,
  },
  Giant: {
    sight: -1,
    weight: 14999,
    fingers: 6,
    wielding: 2,
    str: 9,
    con: 9,
    int: -4,
    wis: -9,
    dex: -6,
    cha: -2,
  },
  Gnoll: {
    sight: 1,
    weight: 8500,
    fingers: 5,
    wielding: 2,
    str: 2,
    con: 9,
    int: 0,
    wis: 0,
    dex: 1,
    cha: 1,
  },
  Gnome: {
    sight: 2,
    weight: 8000,
    fingers: 4,
    wielding: 2,
    str: -1,
    con: -1,
    int: 5,
    wis: 5,
    dex: 1,
    cha: -7,
  },
  Goblin: {
    sight: 1,
    weight: 8000,
    fingers: 5,
    wielding: 2,
    str: 7,
    con: 6,
    int: -1,
    wis: -1,
    dex: 5,
    cha: -9,
  },
  Halfling: {
    sight: 3,
    weight: 7000,
    fingers: 5,
    wielding: 2,
    str: -2,
    con: -3,
    int: -1,
    wis: 0,
    dex: 9,
    cha: -4,
  },
  'Half-elf': {
    sight: 2,
    weight: 7500,
    fingers: 4,
    wielding: 2,
    str: 0,
    con: -2,
    int: -2,
    wis: -5,
    dex: 4,
    cha: 2,
  },
  'Half-ogre': {
    sight: 1,
    weight: 10500,
    fingers: 6,
    wielding: 2,
    str: 6,
    con: 8,
    int: -5,
    wis: -8,
    dex: 3,
    cha: -5,
  },
  'Half-orc': {
    sight: 2,
    weight: 9000,
    fingers: 4,
    wielding: 2,
    str: 5,
    con: 6,
    int: -1,
    wis: -3,
    dex: 1,
    cha: -6,
  },
  Hobbit: {
    sight: 1,
    weight: 4500,
    fingers: 4,
    wielding: 2,
    str: -1,
    con: -3,
    int: -2,
    wis: -4,
    dex: 8,
    cha: 1,
  },
  Human: {
    sight: 0,
    weight: 9999,
    fingers: 5,
    wielding: 2,
    str: -1,
    con: -1,
    int: 1,
    wis: 0,
    dex: 0,
    cha: 3,
  },
  Imp: {
    sight: 1,
    weight: 6001,
    fingers: 5,
    wielding: 2,
    str: -7,
    con: -7,
    int: 9,
    wis: 9,
    dex: 9,
    cha: 0,
  },
  Kender: {
    sight: 0,
    weight: 4999,
    fingers: 5,
    wielding: 2,
    str: -5,
    con: 5,
    int: 4,
    wis: -8,
    dex: 7,
    cha: 4,
  },
  Kobold: {
    sight: 2,
    weight: 9500,
    fingers: 5,
    wielding: 2,
    str: 3,
    con: 3,
    int: 1,
    wis: 1,
    dex: 3,
    cha: 0,
  },
  Lich: {
    sight: 4,
    weight: 8000,
    fingers: 5,
    wielding: 2,
    str: 0,
    con: 8,
    int: 9,
    wis: 9,
    dex: 0,
    cha: -9,
  },
  Nymph: {
    sight: 0,
    weight: 8500,
    fingers: 5,
    wielding: 2,
    str: -4,
    con: -1,
    int: -5,
    wis: -9,
    dex: 8,
    cha: 9,
  },
  Ogre: {
    sight: 0,
    weight: 11000,
    fingers: 6,
    wielding: 2,
    str: 7,
    con: 9,
    int: -6,
    wis: -9,
    dex: 2,
    cha: -9,
  },
  'Ogre-magi': {
    sight: 1,
    weight: 11500,
    fingers: 6,
    wielding: 2,
    str: 7,
    con: 8,
    int: 6,
    wis: 6,
    dex: 2,
    cha: -7,
  },
  Orc: {
    sight: 1,
    weight: 11500,
    fingers: 6,
    wielding: 2,
    str: 7,
    con: 9,
    int: -5,
    wis: -9,
    dex: -1,
    cha: -7,
  },
  Satyr: {
    sight: 0,
    weight: 10050,
    fingers: 4,
    wielding: 2,
    str: 6,
    con: 6,
    int: -7,
    wis: -5,
    dex: 2,
    cha: -8,
  },
  Troll: {
    sight: 2,
    weight: 9000,
    fingers: 5,
    wielding: 2,
    str: 4,
    con: 3,
    int: 0,
    wis: 0,
    dex: 0,
    cha: -3,
  },
  'Were-wolf': {
    sight: 3,
    weight: 14000,
    fingers: 5,
    wielding: 0,
    str: 9,
    con: 9,
    int: -5,
    wis: -5,
    dex: 9,
    cha: -5,
  },
};
